import React, { useState } from 'react'

import TeamMembersRow from './TeamMembersRow'

export default function TeamMembers( { teamMembers } ) {

	const [ rowSelected, setRowSelected ] = useState()

	teamMembers.sort( ( a, b ) => {
		return a.id - b.id
	} )

	const membersGrouped = chunkArray( teamMembers, 4 )

	function chunkArray( array, size ) {
		let result = []
		let arrayCopy = [ ...array ]

		while ( arrayCopy.length > 0 ) {
			result.push(arrayCopy.splice(0, size))
		}

		return result
	}

	return (
		<section className="wrapper">
			<div className="team-members" id="team-members">
				<div className="team-members__header">
					<h1 className="team-members__heading">Meet the team</h1>
				</div>

				{ membersGrouped.map( ( group, i ) => (
					<TeamMembersRow
						key={ i }
						teamMembers={ group }
						rowIndex={ i }
						rowSelected={ rowSelected }
						setRowSelected={ setRowSelected } />
				) ) }
			</div>
		</section>
	)
}