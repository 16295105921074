import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

export default function TeamMembers( props ) {

	const { teamMembers, rowIndex, rowSelected, setRowSelected } = props
	const [ activeMember, setActiveMember ] = useState()
	const [ readMoreActive, setReadMoreActive ] = useState()

	useEffect(() => {
		if ( rowSelected !== rowIndex ) {
			setActiveMember()
		}
	}, [ rowIndex, rowSelected ] )

	const handleClick = ( member ) => {

		setReadMoreActive( false )

		if ( activeMember && activeMember.id === member.id ) {
			setActiveMember()
			setRowSelected()
		} else {
			setActiveMember( member )

			// add quick delay before scrolling down to allow render 1/10th second
			setTimeout(() => {
				const selectedContainer = document.getElementById( `#team-members-${ rowIndex }` )
				selectedContainer.scrollIntoView({ behavior: 'smooth', block: 'end' })
			}, 100)

			setRowSelected( rowIndex )
		}
	}

	const handleReadMore = () => {
		setReadMoreActive( true )
		window.scrollBy({ top: 80, behavior: 'smooth' })
	}

	return (
		<div className="team-members__container">

			<div className="team-members__group-container">
				{ teamMembers.map( member => (
					<button className={`team-members__member ${ activeMember && activeMember.id === member.id ? 'active' : '' }`} key={ member.id } onClick={ () => handleClick( member ) }>
						<div className="team-members__image-container">
							<Img fluid={{ ...member.teamImage.imageFile.childImageSharp.fluid, aspectRatio: 1, cropFocus: 'left' }} className="team-members__image" />
						</div>
						<div className="team-members__name">
							{ member.teamName }
							<span className="team-members__name-icon">+</span>
						</div>
						<div className="team-members__role">{ member.teamJobRole }</div>
					</button>
				) ) }
			</div>

			{ activeMember &&
				<div className="team-members__selected-container" id={ `#team-members-${ rowIndex }` }>
					<div className="team-members__selected-image-container">
						<Img fluid={{ ...activeMember.teamImage.imageFile.childImageSharp.fluid, aspectRatio: 11/7 }} className="team-members__selected-image" />
					</div>
					<div className="team-members__selected-text-container">
						<div className="team-members__selected-name">{ activeMember.teamName }</div>
						<div className="team-members__selected-role">{ activeMember.teamJobRole }</div>

						<ReactMarkdown source={ activeMember.teamIntro } escapeHtml={ false } className="team-members__selected-intro" />

						{ activeMember.teamExtraInfo && !readMoreActive &&
							<button className="team-members__read-more" onClick={ () => handleReadMore() }>
								Read more...
							</button>
						}
						<button className="team-members__name-icon team-members__name-icon--close" onClick={ () => handleClick( activeMember )}>+</button>
					</div>

					{ activeMember.teamExtraInfo && readMoreActive &&
						<div className="team-members__extra-info-container">
							<ReactMarkdown source={ activeMember.teamExtraInfo } escapeHtml={ false } className="team-members__extra-info" />
						</div>
					}
				</div>
			}
		</div>
	)
}