import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/Seo'

import DynamicZone from '../components/DynamicZone/DynamicZone'
import TeamMembers from '../components/TeamMembers/TeamMembers'

export default function Home({ data: { strapi: { aboutUsPage, teamMembers } }, location }) {

	return (
		<>
			<SEO title="About Us" description={ aboutUsPage.seoDescription } pathname={ location.pathname } />

			{ aboutUsPage && aboutUsPage.content &&
				<DynamicZone components={ aboutUsPage.content } />
			}

			{ teamMembers &&
				<TeamMembers teamMembers={ teamMembers } />
			}
		</>
	)
}

export const query = graphql`
	query aboutUsPageQuery {
		strapi {
			aboutUsPage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentGeneralGeneralIllustrationBanner {
						id
						generalIllustrationDescription
						generalIllustrationImage
						generalIllustrationLinkText
						generalIllustrationLinkUrl
						generalIllustrationTitle
					}
					... on StrapiQuery_ComponentAboutAboutContentBanner {
						id
						aboutContentBannerIllustration
						aboutContentBannerText
					}
				}
			}
			teamMembers {
				id
				teamExtraInfo
				teamIntro
				teamJobRole
				teamName
				teamImage {
					url
					imageFile {
						childImageSharp {
							fluid(maxWidth: 750) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			}
		}
	}
`